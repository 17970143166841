<!--
 * @Description: 设计师详情页面swiper
 * @Author: zhang zhen
 * @Date: 2024-09-25 16:59:01
 * @LastEditors: zhang zhen
 * @LastEditTime: 2024-09-26 17:29:16
 * @FilePath: /page-sass/src/views/homePage/modules/productSwiper.vue
-->

<template>
  <div class="product-swiper" :class="{ autoHeight: !productList || !productList.length }">
    <!-- 没图的时候 -->
    <template v-if="!productList || !productList.length">
      <div class="emptyInfo">
        <img src="/newEmpty.png" alt="" class="icon" />
        <span class="desc">暂无数据</span>
      </div>
    </template>
    <template v-else>
      <div class="swiper-container">
        <div class="swiper-wrapper">
          <div
            class="swiper-slide pic-box"
            :class="{ active: index === activeIndex }"
            v-for="(item, index) in productList"
            :key="index"
            @click="handleChangeSwiper(index)"
          >
            <img :src="item" alt="产品图片" class="pic" />
          </div>
        </div>
        <div class="swiper-button-next">
          <img src="/product_right_arrow.png" alt="" class="new_arrow" />
        </div>
        <div class="swiper-button-prev">
          <img src="/product_left_arrow.png" alt="" class="new_arrow" />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
export default {
  props: {
    productList: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      activeIndex: 0,
      swiper: null,
    }
  },
  mounted() {
    setTimeout(() => {
      this.$nextTick(() => {
        this.activeIndex = 0
        this.handleInitData()
      })
    }, 500)
  },
  methods: {
    handleChangeSwiper(index) {
      this.activeIndex = index
      this.$emit('changeSwiper', index)
    },
    handleJumpIndex(index) {
      this.swiper.slideTo(index) // Swiper 滚动到指定下标
      this.activeIndex = index;
    },
    handleInitData() {
      this.swiper = new Swiper('.swiper-container', {
        slidesPerView: 6,
        spaceBetween: 12,
        slidesPerGroup: 3,
        loop: false,
        loopFillGroupWithBlank: false,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        on: {
          slideChange: () => {
            // 获取当前的活动 index
            this.handleChangeSwiper(this.swiper.activeIndex)
          },
        },
      })
    },
  },
}
</script>

<style lang="less" scoped>
.product-swiper {
  width: 1112px;
  height: 92px;
  position: relative;
  &.autoHeight {
    height: auto;
    margin-bottom: 15px;
  }
  margin-top: 20px;
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
  &:hover {
    .swiper-button-next,
    .swiper-button-prev {
      display: block;
    }
  }
  .swiper-container {
    width: 100%;
    height: 100%;
    .swiper-button-next,
    .swiper-button-prev {
      &::after {
        display: none;
      }
    }
    .swiper-button-next {
      right: 0;
    }
    .swiper-button-prev {
      left: 0;
    }
    .pic-box {
      max-width: 154px;
      width: 154px;
      min-width: 154px;
      height: 92px;
      position: relative;
      .inlineDesc {
        width: 300px;
      }
    }
  }
  .emptyInfo {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    .desc {
      font-family: PingFang SC;
      font-size: 12px;
      font-weight: 400;
      line-height: 12px;
      text-align: center;
      color: #9c9ea9;
    }
    img.icon {
      width: 118px;
      margin-bottom: 10px;
    }
  }
  .pic-box {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
    &.active {
      border: 3px solid #ff6026;
      img {
        border-radius: 0;
      }
    }
    img {
      width: 100%;
      height: 100%;
      border-radius: 8px;
      object-fit: cover;
      aspect-ratio: 16/9;
    }
  }
  .new_arrow {
    width: 38px;
    height: 36px;
  }
}
</style>
